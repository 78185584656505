import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import { ImageCarouselModal } from '@/components/atomic/atoms';
import { mediaStoreBaseURL, staticMediaStoreBaseURL } from '@/config/common';
import { getRankedImages, itemImageDefault } from '@/helpers/carousel';
import { useIsMobile } from '@/helpers/screenResolutionHelper';

const parseProductMedia = (productMediaRaw) => {
  if (productMediaRaw.length === 0) return [itemImageDefault];
  return productMediaRaw.map(({ sortOrder, media: { id, url, source } }) => ({
    id,
    original: `${mediaStoreBaseURL}/${url}`,
    sortOrder,
    source,
    thumbnail: `${mediaStoreBaseURL}/${url}`,
    url
  }));
};

const PDPProductImageSlider = ({ productMedia = [] }) => {
  const [isMobile] = useIsMobile();
  const imageSliderRef = useRef();
  const isProductMediaFound = productMedia.length > 0;
  const imageList = getRankedImages(parseProductMedia(productMedia));
  const [viewImageInFullScreen, setViewImageInFullScreen] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageCarouselModalImage, setImageCarouselModalImage] = useState(false);

  useEffect(() => {
    imageSliderRef?.current.exitFullScreen();
  }, []);

  return (
    <>
      <div
        className={`mt-3 ${
          viewImageInFullScreen
            ? 'banner-overlay-thumbnail-full-screen'
            : 'banner-overlay-thumbnail'
        }`}
      >
        <ImageGallery
          ref={imageSliderRef}
          items={imageList}
          showPlayButton={false}
          slideOnThumbnailOver
          showBullets={true}
          showNav={viewImageInFullScreen}
          onClick={() => {
            setImageCarouselModalImage(imageList[currentIndex]);
          }}
          thumbnailPosition={isMobile ? 'bottom' : 'right'}
          useBrowserFullscreen={!isMobile}
          onSlide={(currentIndexOfImage) =>
            setCurrentIndex(currentIndexOfImage)
          }
          renderFullscreenButton={(onClick, isFullscreen) =>
            isProductMediaFound && (
              <div className='absolute top-2 left-2'>
                <Image
                  {...{
                    alt: 'open product image',
                    className: 'cursor-pointer',
                    height: isMobile ? 24 : 32,
                    onClick: () =>
                      setImageCarouselModalImage(imageList[currentIndex]),
                    isFullscreen: setViewImageInFullScreen(isFullscreen),
                    src: `${staticMediaStoreBaseURL}/icons/resize-icon.svg`,
                    width: isMobile ? 24 : 32
                  }}
                />
              </div>
            )
          }
          renderRightNav={(onClick) => (
            <div className='absolute top-2/4 right-5 z-10'>
              <Image
                {...{
                  alt: 'right nav',
                  className: 'cursor-pointer',
                  height: isMobile ? 24 : 40,
                  onClick,
                  src: `${staticMediaStoreBaseURL}/icons/right-nav-carousel.svg`,
                  width: isMobile ? 24 : 40
                }}
              />
            </div>
          )}
          renderLeftNav={(onClick) => (
            <div className=' rotate-180 absolute top-2/4 left-5 z-10'>
              <Image
                {...{
                  alt: 'left nav',
                  className: 'cursor-pointer',
                  height: isMobile ? 24 : 40,
                  onClick,
                  src: `${staticMediaStoreBaseURL}/icons/left-nav-carousel.svg`,
                  width: isMobile ? 24 : 40
                }}
              />
            </div>
          )}
        />
      </div>
      {imageCarouselModalImage && (
        <ImageCarouselModal
          {...{
            images: imageList,
            modalImage: imageCarouselModalImage,
            openImageModal: setImageCarouselModalImage
          }}
        />
      )}
    </>
  );
};

export default PDPProductImageSlider;
