import { useRouter } from 'next/router';

import { Container, RibbonButton } from '@/components/atomic/atoms';
import { PAGE_NAME, captureGTMEventCTAClick } from '@/lib/gtm';

const RibbonContainer = ({
  buttonTitle,
  buttonUrl,
  ctaName,
  isEventPlan,
  isOpenInSamePage,
  subTitle,
  title
}) => {
  const Router = useRouter();
  return (
    <Container
      style={{
        justifyContent: 'space-around',
        height: `${isEventPlan ? '100px' : '200px'}`
      }}
      className={` w-full bg-nero text-center flex items-center ${
        isEventPlan ? 'h-28 flex-col' : 'h-52 justify-around'
      }`}
    >
      <div>
        <div
          className={`font-bold text-white ${
            isEventPlan ? 'text-l mt-6' : 'text-4xl'
          }`}
        >
          {title}
        </div>
        {subTitle && (
          <div className='text-base text-white font-bold'>{subTitle}</div>
        )}
      </div>
      <RibbonButton
        isEventPlan={isEventPlan}
        className={`rounded-full uppercase px-6 md:py-3  ${
          isEventPlan
            ? 'h-6 mb-6 tracking-normal'
            : 'h-11 tracking-wider w-full'
        }`}
        onClick={() => {
          captureGTMEventCTAClick({
            ctaName,
            pageName: PAGE_NAME.HOME_PAGE.label
          });

          return isOpenInSamePage
            ? Router.push(buttonUrl)
            : window.open(buttonUrl, '_blank');
        }}
      >
        {buttonTitle}
        <span style={{ fontSize: `${isEventPlan ? 10 : 19}` }}> →</span>
      </RibbonButton>
    </Container>
  );
};

export default RibbonContainer;
