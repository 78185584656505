import Image from 'next/image';

import { Text } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const PageNotFound = ({ message, subMessage1, subMessage2 = '' }) => (
  <div className='flex gap-2 justify-center h-126 my-6'>
    <div className='self-center'>
      <div className='p-10'>
        <Image
          alt='page not found'
          src={`${staticMediaStoreBaseURL}/icons/page-not-found.svg`}
          height={10}
          width={10}
          layout='responsive'
        />
      </div>

      <Text
        {...{
          content: message,
          className: 'text-lg font-semibold text-center'
        }}
      />
      <Text
        {...{
          content: subMessage1,
          className: 'text-center font-light text-md w-full'
        }}
      />
      <Text
        {...{
          content: subMessage2,
          className: 'text-center font-light text-md'
        }}
      />
    </div>
  </div>
);

export default PageNotFound;
