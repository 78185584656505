import { Button } from '@/components/atomic/atoms';
import { PRODUCT_STATUS } from '@/config/common';
import { PAGE_NAME } from '@/lib/gtm';
import { isPlannerLoggedIn } from '@/services/identity.service';
import { handleAddToCart } from '@/services/product.service';

const RFQProductAddToCart = ({
  createCart,
  isQuantityInvalid,
  product,
  quantity,
  revertQuantityToMin,
  setShowAddedToCartAlert,
  updateCartInPortalHeader
}) => (
  <div className='z-10 w-full'>
    {product.status === PRODUCT_STATUS.PUBLISHED ? (
      <Button
        {...{
          children: 'Add to Cart',
          className: `h-11 md:h-auto bg-gradient-to-r from-light-orange via-dark-orange to-rose px-16 md:py-4 w-full text-white text-base focus:outline-none rounded-lg`,
          disabled: isPlannerLoggedIn,
          onClick: () =>
            handleAddToCart({
              createCart,
              isQuantityInvalid,
              pageName: PAGE_NAME.PDP.label,
              product,
              quantity,
              revertQuantityToMin,
              setShowAddedToCartAlert,
              updateCartInPortalHeader
            })
        }}
      />
    ) : (
      <Button
        {...{
          children: 'Unavailable',
          className: `text-white bg-gradient-to-r from-light-orange via-dark-orange to-rose p-3 rounded-lg md:px-16 text-sm md:text-base font-medium opacity-50 cursor-not-allowed`,
          disabled: true
        }}
      />
    )}
  </div>
);

export default RFQProductAddToCart;
