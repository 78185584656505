import { isEmpty } from 'lodash';
import { Modal, ModalBody } from 'reactstrap';

import { CloseIcon, Text } from '@/components/atomic/atoms';
import { CartListCard } from '@/components/atomic/molecules';

const UserEventInfo = ({ eventDate, eventTitle } = {}) => (
  <div className='flex mt-4 mx-auto w-full md:w-80 text-base font-medium text-white'>
    <div className='bg-border py-3 px-5 w-25 rounded-l-lg'>{eventDate}</div>
    <div className='py-3 px-4 flex-1 bg-nero opacity-80 rounded-r-lg truncate'>
      {eventTitle}
    </div>
  </div>
);

const UserEventCartCardList = ({
  cartList,
  resetSelectedCart,
  setShowCartSelectorModal,
  updateSelectedCart
}) => (
  <div className='flex flex-wrap gap-22 mt-7'>
    {cartList.map(
      (
        {
          cartName,
          status,
          cartNumber,
          isSelected,
          id,
          eventId: userEventId = '',
          isDefault
        },
        index
      ) => (
        <CartListCard
          key={index}
          {...{
            cartName,
            cartNumber,
            id,
            isDefault,
            isSelected,
            resetSelectedCart,
            setShowCartSelectorModal,
            status,
            updateSelectedCart,
            userEventId
          }}
        />
      )
    )}
  </div>
);

const CartNavigator = ({
  cartList,
  resetSelectedCart,
  setShowCartSelectorModal,
  updateSelectedCart,
  userEventInfo
}) => (
  <div
    className={`flex flex-col justify-start px-4 md:px-8 -mb-28 h-126 md:h-auto overflow-scroll md:overflow-hidden mx-auto w-full md:w-66.75 fixed md:relative bottom-0 bg-white md:rounded-lg py-5`}
  >
    <div className='flex md:block justify-between md:justify-start'>
      <CloseIcon
        {...{
          className: 'flex justify-end order-2',
          onClick: () => setShowCartSelectorModal(false),
          iconClassName: 'cursor-pointer'
        }}
      />
      <Text
        content='Select Cart'
        className='font-medium text-base md:text-xl text-center order-1 md:order-2'
      />
    </div>
    {!isEmpty(userEventInfo) && <UserEventInfo {...userEventInfo} />}
    <UserEventCartCardList
      {...{
        cartList,
        resetSelectedCart,
        setShowCartSelectorModal,
        updateSelectedCart
      }}
    />
  </div>
);

const SelectCartModalOrganism = ({
  cartList = [],
  resetSelectedCart,
  setShowCartSelectorModal,
  updateSelectedCart,
  userEventInfo
}) => (
  <Modal
    centered
    size='lg'
    isOpen={true}
    toggle={setShowCartSelectorModal}
    backdrop={true}
    fade={false}
  >
    <ModalBody className='py-5'>
      <CartNavigator
        {...{
          cartList,
          resetSelectedCart,
          setShowCartSelectorModal,
          updateSelectedCart,
          userEventInfo
        }}
      />
    </ModalBody>
  </Modal>
);

export default SelectCartModalOrganism;
