import { useState } from 'react';

import { Button, InputField } from '@/components/atomic/atoms';

const InputPromoCode = ({
  derivedValues: { promoCode: { code } = {} } = {},
  processApplyPromoCode,
  promoCodeError,
  setPromoCodeError
}) => {
  const [promoCode, setPromoCode] = useState(code || '');
  const onClickSubmit = () => {
    setPromoCodeError('');
    processApplyPromoCode(promoCode);
  };
  return (
    <div>
      <div className='flex'>
        <div className='w-full'>
          <InputField
            {...{
              className: 'rounded-l-lg py-4',
              onChange: (e) => {
                setPromoCode(e.target.value.toUpperCase());
              },
              placeholder: 'Enter promo code',
              value: promoCode
            }}
          />
        </div>
        <Button
          {...{
            children: 'Apply',
            className:
              'bg-black text-white rounded-r-lg py-4 text-base font-medium',
            onClick: onClickSubmit,
            width: 'w-32'
          }}
        />
      </div>
      {promoCodeError && (
        <div className='text-xs text-red-500'>{promoCodeError}</div>
      )}
    </div>
  );
};

export default InputPromoCode;
