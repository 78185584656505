import { Divider, RichTextEditor, Text } from '@/components/atomic/atoms';

const ProductDescription = ({ description }) => (
  <div>
    <div className='product-description'>
      <Text
        {...{
          content: 'Description',
          className: 'text-nero text-base md:text-xl font-semibold'
        }}
      />
      <div className='font-light text-xs md:text-sm text-nero mt-4 leading-5'>
        <RichTextEditor
          {...{
            readOnly: true,
            values: description
          }}
        />
      </div>
    </div>
    <Divider {...{ space: 'mt-8 mb-6' }} />
  </div>
);

export default ProductDescription;
