import { ChargesLabel, Price, PriceLabel } from '@/components/atomic/atoms';

const PriceSummaryLabel = ({ field, label, isBracket = true }) => (
  <div className='flex justify-between leading-4 pt-3'>
    <ChargesLabel>
      {label}
      <PriceLabel {...{ isBracket }} />
    </ChargesLabel>
    <Price value={field} />
  </div>
);

export default PriceSummaryLabel;
