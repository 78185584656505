import { ChargesLabel, Price } from '@/components/atomic/atoms';

const NonTaxableCharge = ({ nonTaxableCharge }) =>
  nonTaxableCharge > 0 && (
    <div className='flex justify-between leading-4 pt-3'>
      <ChargesLabel>Other Charges (non-taxable)</ChargesLabel>
      <Price {...{ value: nonTaxableCharge || 0.0 }} />
    </div>
  );

export default NonTaxableCharge;
