import { useRouter } from 'next/router';

import { CollectionSubHeader, RibbonButton } from '@/components/atomic/atoms';
import {
  GTM_EVENTS_CTA_NAME,
  PAGE_NAME,
  captureGTMEventCTAClick
} from '@/lib/gtm';

const CollectionHeader = ({
  title,
  subTitle,
  buttonText,
  buttonUrl,
  isOpenInSamePage
}) => {
  const Router = useRouter();
  return (
    <div className='flex flex-row justify-between '>
      <CollectionSubHeader
        {...{
          title,
          subTitle,
          underline: true,
          subPosition: 'top',
          titleClass: 'flex flex-row uppercase tracking-widest'
        }}
      />
      <div className='self-end'>
        {buttonText && (
          <RibbonButton
            className='rounded-full uppercase px-8 py-2 tracking-wider'
            onClick={() => {
              captureGTMEventCTAClick({
                ctaName: GTM_EVENTS_CTA_NAME.GALLERY_VIEW_ALL,
                pageName: PAGE_NAME.HOME_PAGE.label
              });
              return isOpenInSamePage
                ? Router.push(buttonUrl)
                : window.open(buttonUrl, '_blank');
            }}
          >
            {buttonText}
            <span style={{ fontSize: 19 }}> →</span>
          </RibbonButton>
        )}
      </div>
    </div>
  );
};

export default CollectionHeader;
