import { Text } from '@/components/atomic/atoms';

const HostCartPriceSummaryRow = ({
  isVisible = true,
  subTitle,
  subTitleClass,
  title,
  value,
  valueClass
}) =>
  isVisible && (
    <div className='flex justify-between my-2 md:my-4 text-sm font-light md:font-normal text-nero'>
      <div className='flex gap-1'>
        <Text {...{ content: title }} />
        {subTitle && (
          <Text {...{ content: `(${subTitle})`, className: subTitleClass }} />
        )}
      </div>
      <Text {...{ content: value, className: valueClass }} />
    </div>
  );

export default HostCartPriceSummaryRow;
