const PricedItemListOrganism = ({
  CartItemRow,
  cartItemTitleClass,
  deleteItem,
  isCartEditable,
  itemList = [],
  priceLabelClass,
  updateCartItemDetail
}) => (
  <div className='border border-platinum px-3 py-4 md:p-6 rounded-2.5 flex flex-col gap-6'>
    {itemList.map((items, index) => (
      <CartItemRow
        key={index}
        {...{
          ...items,
          cartItemTitleClass,
          deleteItem,
          isCartEditable,
          lastCartItem: itemList.length - 1 === index,
          priceLabelClass,
          updateCartItemDetail
        }}
      />
    ))}
  </div>
);

export default PricedItemListOrganism;
