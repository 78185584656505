import { validate, version } from 'uuid';

import { NAMED_QUERY_PARAMS, PAYMENT_STATUS } from '@/config/common';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';
import { HOST_PORTAL_URL } from '@/services/connections.service';
import * as HttpService from '@/services/http.service';
import {
  CHECKOUT_CART_AND_PAY,
  GET_ANYBODY_CAN_PAY_ORDER_DUE_PAYMENT_INFO,
  GET_CART_CHECKOUT_INFO,
  GET_PAYMENT_STATUS,
  POST_ANYBODY_CAN_PAY_ORDER_DUE_PAYMENT_URL_REQUEST
} from '@/services/url.service';

export const PAYMENT_RECEIPT_STATUS = {
  ACKNOWLEDGE: 'acknowledge',
  FAIL: 'fail',
  PASS: 'pass'
};

export const PAYMENT_SOURCE_TYPE = {
  ORDER: 'order',
  CART: 'cart'
};

const isUUIDv4 = (uuid) => validate(uuid) && version(uuid) === 4;

export const getModuleType = ({ referenceId, userId, paymentSourceType }) => {
  const isValidReferenceId = isUUIDv4(referenceId);
  const isValidUserId = isUUIDv4(userId);
  const isValidType = [
    PAYMENT_SOURCE_TYPE.ORDER,
    PAYMENT_SOURCE_TYPE.CART
  ].includes(paymentSourceType);
  if (isValidReferenceId && isValidUserId && isValidType) {
    return paymentSourceType;
  }
  return null;
};

const getCartCheckoutInfoAPI = ({ cartId, userCartId }) =>
  HttpService.getWithoutAuth(GET_CART_CHECKOUT_INFO({ cartId, userCartId }));

export const checkoutCartAndPayAPI = ({ cartId, userCartId }) =>
  HttpService.postWithoutAuth(CHECKOUT_CART_AND_PAY({ cartId, userCartId }));

const getAnybodyCanPayOrderDuePaymentInfo = ({ orderId, userId }) =>
  HttpService.getWithoutAuth(
    GET_ANYBODY_CAN_PAY_ORDER_DUE_PAYMENT_INFO({ orderId, userId })
  );

const postAnybodyCanPayOrderDuePaymentURLRequest = ({ orderId, userId }) =>
  HttpService.postWithoutAuth(
    POST_ANYBODY_CAN_PAY_ORDER_DUE_PAYMENT_URL_REQUEST({ orderId, userId })
  );

const getPaymentStatusAPI = ({ orderId, paymentId, userCartId }) =>
  HttpService.getWithoutAuth(
    GET_PAYMENT_STATUS({ orderId, paymentId, userCartId })
  );

const getPaymentReceiptStatus = (paymentStatus) => {
  if (paymentStatus === PAYMENT_STATUS.PAID) {
    return PAYMENT_RECEIPT_STATUS.PASS;
  }
  if (
    [
      PAYMENT_STATUS.FAILED,
      PAYMENT_STATUS.VOID,
      PAYMENT_STATUS.SUSPENDED
    ].includes(paymentStatus)
  ) {
    return PAYMENT_RECEIPT_STATUS.FAIL;
  }
  return PAYMENT_RECEIPT_STATUS.ACKNOWLEDGE;
};

export const refreshPaymentResponse = async ({
  asPath,
  paymentStatusApiInput,
  routeToPath,
  resetCountDownTo,
  setCountdown,
  setShowToast
}) => {
  getPaymentStatusAPI(paymentStatusApiInput)
    .then(({ entity, message, status }) => {
      if (status) {
        const { paymentStatus, paymentFlowSource, transactionAmount } =
          entity || {};
        const paymentReceiptStatus = getPaymentReceiptStatus(paymentStatus);
        if (paymentReceiptStatus !== PAYMENT_RECEIPT_STATUS.ACKNOWLEDGE) {
          const url = new URL(`${HOST_PORTAL_URL}${asPath}`);
          url.searchParams.set(
            NAMED_QUERY_PARAMS.PAYMENT_RECEIPT_STATUS,
            paymentReceiptStatus
          );
          url.searchParams.set(
            NAMED_QUERY_PARAMS.PAYMENT_FLOW_SOURCE,
            paymentFlowSource
          );
          url.searchParams.set(
            NAMED_QUERY_PARAMS.TRANSACTION_AMOUNT,
            transactionAmount
          );
          routeToPath(url.toString());
        } else {
          setCountdown(resetCountDownTo);
        }
      } else {
        setShowToast({
          show: true,
          status: false,
          message
        });
      }
    })
    .catch((error) => {
      setShowToast({
        show: true,
        status: false,
        message: error.response?.data?.message || 'Failed to obtain response'
      });
    });
};

export const invokePostOrderPaymentDue = async ({
  referenceId,
  Router,
  setShowToast,
  setInvokePaymentAPI,
  userId
}) => {
  await postAnybodyCanPayOrderDuePaymentURLRequest({
    orderId: referenceId,
    userId
  })
    .then(({ status, entity, message }) => {
      if (status) {
        Router.replace(entity.checkoutUrl);
      } else {
        setShowToast({
          show: true,
          status: false,
          message
        });
      }
    })
    .catch((error) => {
      setShowToast({
        show: true,
        status: false,
        message: error.response?.data?.message || 'API Failed'
      });
    });
  setInvokePaymentAPI(false);
};

export const getOrderPaymentDueInfo = ({
  referenceId,
  setShowPageNotFound,
  setShowToast,
  setUserPaymentInformation,
  userId
}) => {
  getAnybodyCanPayOrderDuePaymentInfo({ orderId: referenceId, userId })
    .then(({ entity, message, status }) => {
      if (status) {
        setUserPaymentInformation(entity);
      } else {
        setShowPageNotFound(true);
      }
      setShowToast({ show: true, status, message });
    })
    .catch((error) =>
      setShowToast({
        show: true,
        status: false,
        message: error.response?.data?.message || 'API Failed'
      })
    );
};

export const getCartCheckoutInfo = ({
  referenceId,
  setShowPageNotFound,
  setShowToast,
  setUserPaymentInformation,
  userId
}) => {
  getCartCheckoutInfoAPI({ cartId: referenceId, userCartId: userId })
    .then(({ entity, message, status }) => {
      if (status) {
        setUserPaymentInformation(entity);
      } else {
        setShowPageNotFound(true);
      }
      setShowToast({ show: true, status, message });
    })
    .catch((error) =>
      setShowToast({
        show: true,
        status: false,
        message: error.response?.data?.message || 'API Failed'
      })
    );
};

export const invokeCheckoutCartAndPay = async ({
  referenceId,
  Router,
  setInvokePaymentAPI,
  setShowToast,
  userId
}) => {
  await checkoutCartAndPayAPI({
    cartId: referenceId,
    userCartId: userId
  })
    .then(({ status, entity, message }) => {
      if (status) {
        Router.replace(entity.checkoutUrl);
      } else {
        setShowToast({
          show: true,
          status: false,
          message
        });
      }
    })
    .catch((error) => {
      setShowToast({
        show: true,
        status: false,
        message: error.response?.data?.message || 'API Failed'
      });
    });
  setInvokePaymentAPI(false);
};

const getViewOrderDetailsURL = ({ isUserLoggedIn, orderId }) =>
  isUserLoggedIn
    ? `${HOST_PORTAL_URL}/my-orders/${orderId}/order-details`
    : `${HOST_PORTAL_URL}/login?redirectTo=/my-orders/${orderId}/order-details`;

const getViewHostCartURL = ({ isUserLoggedIn, cartId }) =>
  isUserLoggedIn
    ? `${HOST_PORTAL_URL}/host-carts/${cartId}`
    : `${HOST_PORTAL_URL}/login?redirectTo=/host-carts/${cartId}`;

export const getOrderPublicPaymentModalInfo = ({
  referenceId,
  Router,
  userPaymentInformation,
  isUserLoggedIn
}) => ({
  duePriceBreakUpLabel: 'Amount Due',
  itemNumber: userPaymentInformation.orderNumber,
  onClickLoginAndViewDetails: () => {
    Router.push(
      getViewOrderDetailsURL({ isUserLoggedIn, orderId: referenceId })
    );
  },
  payAmountCTALabel: `Pay the due amount AED ${parseFormatPriceValueFromAPI(
    userPaymentInformation.amountDue
  )}`,
  paymentPriceBreakUpLabel: 'Amount Paid'
});

export const getCartPublicPaymentModalInfo = ({
  referenceId,
  Router,
  userPaymentInformation,
  isUserLoggedIn
}) => ({
  amountPaid: userPaymentInformation.orderTotal,
  duePriceBreakUpLabel: 'Due Amount',
  itemNumber: userPaymentInformation.cartNumber,
  onClickLoginAndViewDetails: () => {
    Router.push(getViewHostCartURL({ isUserLoggedIn, cartId: referenceId }));
  },
  payAmountCTALabel: `Pay the booking amount AED ${parseFormatPriceValueFromAPI(
    userPaymentInformation.orderTotal
  )}`,
  paymentPriceBreakUpLabel: 'Booking Amount'
});

export const generatePaymentLink = ({
  paymentSourceType,
  referenceId,
  userId
}) => {
  const {
    USER_ID,
    PAYMENT_SOURCE_TYPE: PAYMENT_SOURCE_TYPE_FROM_NAMED_QUERY_PARAM,
    REFERENCE_ID
  } = NAMED_QUERY_PARAMS;

  const url = new URL(`${HOST_PORTAL_URL}/payments`);
  url.searchParams.set(USER_ID, userId);
  url.searchParams.set(
    PAYMENT_SOURCE_TYPE_FROM_NAMED_QUERY_PARAM,
    paymentSourceType
  );
  url.searchParams.set(REFERENCE_ID, referenceId);

  return url.toString();
};

const PAYMENT_FLOW_SOURCE = {
  ANYBODY_CART: 'ANYBODY_CART',
  ANYBODY_ORDER: 'ANYBODY_ORDER',
  HOST_CART_DEFAULT: 'HOST_CART_DEFAULT',
  HOST_CART_NON_DEFAULT: 'HOST_CART_NON_DEFAULT',
  PLANNER_CART: 'PLANNER_CART',
  HOST_ORDER: 'HOST_ORDER',
  PLANNER_ORDER: 'PLANNER_ORDER'
};

export const getPaymentResponseRedirectURLs = ({
  asPath,
  isUserLoggedIn,
  orderId,
  paymentFlowSource,
  paymentReceiptStatus,
  paymentSourceType,
  referenceId,
  userId
}) => {
  const getSuccessRedirectURL = () =>
    getViewOrderDetailsURL({ isUserLoggedIn, orderId });

  const getDefaultCartCheckoutPageURL = () => {
    const url = new URL(`${HOST_PORTAL_URL}/checkout`);
    url.searchParams.set('payment-receipt-status', paymentReceiptStatus);
    return url.toString();
  };

  const getFailureRedirectURL = () => {
    let failureRedirectURL = '';
    switch (paymentFlowSource) {
      case PAYMENT_FLOW_SOURCE.ANYBODY_CART:
      case PAYMENT_FLOW_SOURCE.ANYBODY_ORDER:
        failureRedirectURL = generatePaymentLink({
          paymentSourceType,
          referenceId,
          userId
        });
        break;

      case PAYMENT_FLOW_SOURCE.HOST_ORDER:
      case PAYMENT_FLOW_SOURCE.PLANNER_ORDER:
        failureRedirectURL = `/my-orders/${orderId}/order-details`;
        break;

      case PAYMENT_FLOW_SOURCE.HOST_CART_DEFAULT:
        failureRedirectURL = getDefaultCartCheckoutPageURL();
        break;

      case PAYMENT_FLOW_SOURCE.HOST_CART_NON_DEFAULT:
        failureRedirectURL = `/host-carts/${referenceId}`;
        break;

      case PAYMENT_FLOW_SOURCE.PLANNER_CART:
        failureRedirectURL = `/planner-carts/${referenceId}`;
        break;

      default:
        failureRedirectURL = `/host-carts/${referenceId}`;
        break;
    }
    return failureRedirectURL;
  };

  let forwardURL;
  switch (paymentReceiptStatus) {
    case PAYMENT_RECEIPT_STATUS.FAIL:
      forwardURL = getFailureRedirectURL();
      break;
    case PAYMENT_RECEIPT_STATUS.PASS:
      forwardURL = getSuccessRedirectURL();
      break;
    case PAYMENT_RECEIPT_STATUS.ACKNOWLEDGE:
    default:
      forwardURL = asPath;
      break;
  }

  const redirectURLMap = {
    forwardURL,
    cancelURL: `${HOST_PORTAL_URL}`
  };
  return redirectURLMap;
};
