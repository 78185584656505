import {
  DeliveryInfoMobile,
  GenericKeyValueBox,
  LoginAndPaymentCTA,
  PriceSummary,
  TermsAndConditions
} from '@/components/atomic/molecules';
import { haflaBankDetails } from '@/config/common';
import { GetUiDeviceSurface } from '@/helpers/screenResolutionHelper';
import { transformDeliveryDetailForMobile } from '@/services/hostCart.service';

const PriceSummaryWithDetailWeb = ({
  disableLoginButton,
  disableProceedToPayment,
  isLoginAndPaymentCTAVisible,
  onClickGeneratePaymentLink,
  onClickLogin,
  onClickProceedToPayment,
  paymentDetail,
  summaryDetail
}) => (
  <div className='md:w-150 hidden md:flex flex-col gap-4'>
    <PriceSummary
      {...{
        ...summaryDetail,
        disableLoginButton,
        disableProceedToPayment,
        isLoginAndPaymentCTAVisible,
        onClickLogin,
        onClickProceedToPayment
      }}
    />
    {paymentDetail && (
      <GenericKeyValueBox
        {...{
          boxTitle: 'Payment Info',
          keyValuePairList: paymentDetail,
          buttonPropsHidden: {
            className: `bg-white text-orange border border-orange
                  h-12.5 py-4 rounded-md  text-base
                  font-medium leading-5 w-full mt-8 ml-auto  mb-7 md:mb-0`,
            children: 'Payment Link',
            onClick: onClickGeneratePaymentLink
          }
        }}
      />
    )}
    <GenericKeyValueBox
      {...{
        boxTitle: 'Company Bank Details',
        keyValuePairList: haflaBankDetails
      }}
    />
  </div>
);

const PriceSummaryWithDetailMobile = ({
  disableLoginButton,
  disableProceedToPayment,
  isLoginAndPaymentCTAVisible,
  onClickLogin,
  onClickProceedToPayment,
  paymentDetail,
  summaryDetail,
  termsAndConditions
}) => (
  <div className='flex gap-4 md:hidden flex-col'>
    <PriceSummary
      {...{
        ...summaryDetail,
        onClickLogin
      }}
    />
    <DeliveryInfoMobile {...transformDeliveryDetailForMobile(summaryDetail)} />
    {paymentDetail && (
      <GenericKeyValueBox
        {...{
          boxTitle: 'Payment Info',
          keyValuePairList: paymentDetail
        }}
      />
    )}
    <TermsAndConditions {...{ termsAndConditions }} />
    <LoginAndPaymentCTA
      {...{
        disableLoginButton,
        disableProceedToPayment,
        isLoginAndPaymentCTAVisible,
        onClickLogin,
        onClickProceedToPayment
      }}
    />
  </div>
);

const PriceSummaryWithDetailOrganism = ({
  disableLoginButton,
  disableProceedToPayment,
  isLoginAndPaymentCTAVisible,
  onClickGeneratePaymentLink,
  onClickLogin,
  onClickProceedToPayment,
  paymentDetail,
  summaryDetail,
  termsAndConditions
}) => {
  const surface = GetUiDeviceSurface();

  const PriceSummaryDetailMap = {
    mobile: PriceSummaryWithDetailMobile,
    web: PriceSummaryWithDetailWeb
  };

  const PriceSummaryDetail = PriceSummaryDetailMap[surface];

  return (
    <PriceSummaryDetail
      {...{
        disableLoginButton,
        disableProceedToPayment,
        isLoginAndPaymentCTAVisible,
        onClickGeneratePaymentLink,
        onClickLogin,
        onClickProceedToPayment,
        paymentDetail,
        summaryDetail,
        termsAndConditions
      }}
    />
  );
};

export default PriceSummaryWithDetailOrganism;
