import _ from 'lodash';
import { Node } from 'slate';

const SLATE_SERIALIZER_DELIMITER = '\n';

export const getSerializedRichText = (nodes) => {
  try {
    if (_.isEmpty(nodes)) {
      return false;
    }

    if (Array.isArray(nodes)) {
      return nodes
        .map((node) => Node.string(node))
        .join(SLATE_SERIALIZER_DELIMITER);
    }

    return Node.string(nodes);
  } catch (error) {
    return false;
  }
};

export default getSerializedRichText; // TODO: Remove after add another export function or var.
