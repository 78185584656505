import 'react-datepicker/dist/react-datepicker.css';

import { ProductPriceLabel, Text } from '@/components/atomic/atoms';
import {
  NRFQProductAddToCart,
  ProductQuantityRangeAlertError,
  RFQProductAddToCart,
  SpinnerButton
} from '@/components/atomic/molecules';
import { useUIContext } from '@/components/context/uiContext/ManagedUiContext';
import { PRODUCT_STATUS, PRODUCT_TYPE } from '@/config/common';
import { isPlannerLoggedIn } from '@/services/identity.service';

const SpinnerInput = ({
  changeQuantity,
  handleOnBlur,
  handleValueChange,
  isPublished,
  maxBookablePrice,
  minBookable,
  quantity,
  showMaxBookableAlert,
  showQuantityRangeAlert
}) => (
  <div className='flex justify-between gap-4'>
    <div
      className={`flex flex-row w-36 rounded-md text-2xl border ${
        showQuantityRangeAlert ? 'border-error-red' : 'border-gray-200'
      }`}
    >
      <div
        className={`border-r border-gray-200 bg-gray
        h-12 w-full rounded-l-md focus:outline-none flex
        justify-center items-center cursor-pointer
        ${quantity === minBookable && 'cursor-not-allowed'}`}
        name='decrease'
      >
        <SpinnerButton
          {...{
            className: `${
              quantity === minBookable &&
              !isPublished &&
              'opacity-50 cursor-not-allowed'
            } ${quantity === minBookable && 'cursor-not-allowed'} w-full`,
            content: '-',
            disabled: quantity === minBookable || !isPublished,
            onClick: (e) => changeQuantity(e, 'decrease')
          }}
        />
      </div>
      <input
        className='h-12 w-20 text-center focus:outline-none'
        max={maxBookablePrice || Infinity}
        onBlur={handleOnBlur}
        onChange={(e) => handleValueChange(e.target.value)}
        text='number'
        type='number'
        value={quantity}
      />
      <div
        className={`border-l border-gray-200 bg-gray
       h-12 w-full rounded-r-md focus:outline-none
       flex justify-center items-center cursor-pointer
        ${showMaxBookableAlert && 'cursor-not-allowed'}`}
        name='increase'
      >
        <SpinnerButton
          {...{
            content: '+',
            disabled: showMaxBookableAlert || !isPublished,
            onClick: (e) => changeQuantity(e, 'increase'),
            style: `${
              showMaxBookableAlert &&
              !isPublished &&
              'opacity-50 cursor-not-allowed'
            }`
          }}
        />
      </div>
    </div>
  </div>
);

const Label = ({ label }) => (
  <Text
    {...{
      className: 'text-sm text-brand mt-2 font-medium hidden md:flex',
      content: label
    }}
  />
);

const addToCart = {
  [PRODUCT_TYPE.CART]: NRFQProductAddToCart,
  [PRODUCT_TYPE.QUOTE]: RFQProductAddToCart
};

const ProductPriceBoxOrganism = ({
  decreaseQuantity,
  increaseQuantity,
  isMobile,
  isQuantityInvalid,
  minBookable,
  product,
  quantity,
  revertQuantityToMin,
  setIsQuantityInvalid,
  setQuantity,
  setShowAddedToCartAlert,
  setShowQuantityRangeAlert,
  showMaxBookableAlert,
  showQuantityRangeAlert
}) => {
  const {
    availableDiscount,
    maxBookable: maxBookablePrice,
    price,
    status: productStatus,
    type: productType
  } = product;
  const { createCart, updateCartInPortalHeader } = useUIContext();

  const maxBookable = maxBookablePrice || Infinity;
  const isPublished = productStatus === PRODUCT_STATUS.PUBLISHED;
  const handleChangeQuantity = {
    decrease: decreaseQuantity,
    increase: increaseQuantity
  };

  const handleValueChange = (value) => {
    setShowQuantityRangeAlert(false);
    const inputValue = parseFloat(value);
    if (inputValue < 0) {
      return;
    }
    setQuantity(inputValue);
    setIsQuantityInvalid(
      Boolean(
        inputValue > maxBookable ||
          inputValue < minBookable ||
          Number.isNaN(inputValue)
      )
    );
  };

  const handleOnBlur = (e) => {
    e.stopPropagation();
    if (e.target.value < minBookable || e.target.value === '') {
      setShowQuantityRangeAlert(true);
      setQuantity(minBookable);
    } else if (e.target.value > maxBookable) {
      setShowQuantityRangeAlert(true);
      setQuantity(maxBookable);
    } else {
      setShowQuantityRangeAlert(false);
    }
    setTimeout(() => {
      setIsQuantityInvalid(false);
    }, 1000);
  };

  const changeQuantity = (e, type) => {
    handleChangeQuantity[type]({
      maxBookable,
      minBookable,
      quantity,
      setQuantity,
      setShowQuantityRangeAlert
    });
  };

  const AddToCart = addToCart[productType];
  const showSpinnerInput =
    productType === PRODUCT_TYPE.CART && isPublished && maxBookable > 1;

  return (
    <>
      <div
        className={`product-price-box w-full md:w-1/3 border md:rounded-lg flex
          flex-col self-start pb-3 px-6 md:p-6 fixed md:sticky md:top-24 bottom-0 bg-white`}
      >
        <div className='hidden md:block'>
          <ProductPriceLabel {...{ availableDiscount, price, productType }} />
        </div>
        {maxBookable > 0 && <Label {...{ label: 'Excluding VAT' }} />}
        <div className='flex gap-4 mt-3'>
          {showSpinnerInput && (
            <SpinnerInput
              {...{
                changeQuantity,
                handleOnBlur,
                handleValueChange,
                isPublished,
                maxBookablePrice,
                minBookable,
                quantity,
                showMaxBookableAlert,
                showQuantityRangeAlert
              }}
            />
          )}
          <AddToCart
            {...{
              createCart,
              isMobile,
              isPlannerLoggedIn,
              isQuantityInvalid,
              minBookable,
              product,
              quantity,
              revertQuantityToMin,
              setShowAddedToCartAlert,
              showQuantityRangeAlert,
              updateCartInPortalHeader
            }}
          />
        </div>
        {showQuantityRangeAlert && (
          <ProductQuantityRangeAlertError
            {...{
              className: 'hidden md:block',
              maxBookable,
              minBookable
            }}
          />
        )}
      </div>
    </>
  );
};

export default ProductPriceBoxOrganism;
