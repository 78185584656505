import Image from 'next/image';
import Link from 'next/link';

import { CardMobile } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const RoundButtonWithText = ({ link, imageSrc, bottomText }) => (
  <div className='ps-4 pe-12 block'>
    <Link href={link}>
      <div className='text-center max-w-18 mx-auto'>
        <div className='bg-more-blog h-17 w-17 rounded-full flex justify-center items-center cursor-pointer shadow-search'>
          <div className='relative h-4'>
            <Image
              src={imageSrc}
              alt={bottomText}
              height={15}
              width={21}
            />
          </div>
        </div>
        <span className='text-nero font-medium font-Inter text-xs leading-4 mt-3'>
          {bottomText}
        </span>
      </div>
    </Link>
  </div>
);

const CardListMobile = ({
  cardList = [],
  handleScroll,
  imageBasePath,
  imageSlugPrefix,
  isViewButtonVisible,
  scrollRef,
  viewButtonLink
}) => (
  <div className='ps-2.5 xxxs:ps-5'>
    <div
      className='flex space-x-4 flex-nowrap no-scrollbar items-center card-scroll'
      ref={scrollRef}
      onScroll={handleScroll}
    >
      {cardList.map(
        ({
          bannerImageUrl,
          id,
          link,
          name,
          slug,
          title,
          yoast_head_json: yoastHeadJson
        }) => (
          <CardMobile
            t={console.log(bannerImageUrl)}
            bannerImageUrl={yoastHeadJson?.og_image[0]?.url || bannerImageUrl}
            imageBasePath={imageBasePath}
            key={id}
            slug={link || slug ? `${imageSlugPrefix}${slug}` : ''}
            title={title?.rendered || name}
          />
        )
      )}

      {isViewButtonVisible && (
        <RoundButtonWithText
          link={viewButtonLink}
          imageSrc={`${staticMediaStoreBaseURL}/icons/right-white.svg`}
          bottomText='View All'
        />
      )}
    </div>
  </div>
);

export default CardListMobile;
