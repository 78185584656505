import dynamic from 'next/dynamic';

const VideoCardMobile = dynamic(
  () => import('@/components/atomic/molecules/homePage/mobile/VideoCardMobile'),
  {
    ssr: false
  }
);

const VideoCardListMobile = ({ cardList = [], scrollRef, handleScroll }) => (
  <div className='ps-2.5 xxxs:ps-5'>
    <div
      className='flex space-x-4 flex-nowrap no-scrollbar items-center card-scroll'
      ref={scrollRef}
      onScroll={handleScroll}
    >
      {cardList.map(({ id, title, url, thumbnailUrl }) => (
        <VideoCardMobile
          key={id}
          {...{ title, url, thumbnailUrl }}
        />
      ))}
    </div>
  </div>
);

export default VideoCardListMobile;
