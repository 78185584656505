import { TextKeyValuePair } from '@/components/atomic/atoms';

const ProductInfo = ({ name, shortDescription }) => (
  <TextKeyValuePair
    {...{
      className: 'flex flex-col gap-4',
      label: name,
      labelClass: 'text-xl md:text-3xl font-semibold text-nero leading-6',
      value: shortDescription,
      valueClassName: 'text-brand text-sm md:text-base font-medium'
    }}
  />
);

export default ProductInfo;
