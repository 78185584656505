import { CTA, Text } from '@/components/atomic/atoms';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';

const PriceSummary = ({
  additionalChargeAmount,
  deliveryChargeAmount,
  disableLoginButton = false,
  disableProceedToPayment = false,
  isLoginAndPaymentCTAVisible = true,
  listedDiscountAmount,
  listedPriceAmount,
  lumpSumDiscount,
  nonTaxableCharge,
  onClickLogin,
  onClickProceedToPayment,
  orderTotal,
  preVatAmount,
  promoCode,
  promoCodeDiscountAmount,
  sellingPriceAmount,
  vatAmount
}) => {
  const priceSummaryList = [
    {
      isVisible: Boolean(listedDiscountAmount),
      title: 'Sub Total (AED)',
      value: parseFormatPriceValueFromAPI(listedPriceAmount)
    },
    {
      isVisible: Boolean(listedDiscountAmount),
      title: 'Item Discounts (AED)',
      value: `- ${parseFormatPriceValueFromAPI(listedDiscountAmount)}`,
      valueClass: 'text-green font-medium'
    },
    {
      title: 'Item Total (AED)',
      value: parseFormatPriceValueFromAPI(sellingPriceAmount)
    },
    {
      isVisible: Boolean(deliveryChargeAmount),
      title: 'Delivery Charges (AED)',
      value: parseFormatPriceValueFromAPI(deliveryChargeAmount)
    },
    {
      isVisible: Boolean(additionalChargeAmount),
      title: 'Additional Charges (AED)',
      value: parseFormatPriceValueFromAPI(additionalChargeAmount)
    },
    {
      isVisible: Boolean(promoCodeDiscountAmount),
      subTitle: promoCode?.code || '',
      subTitleClass: 'text-green font-medium',
      title: 'Promo',
      value: `-${parseFormatPriceValueFromAPI(promoCodeDiscountAmount)}`,
      valueClass: 'text-green font-medium'
    },
    {
      isVisible: Boolean(lumpSumDiscount),
      title: 'Bulk Discount (AED)',
      value: `- ${parseFormatPriceValueFromAPI(lumpSumDiscount)}`,
      valueClass: 'text-green font-medium'
    },
    {
      title: 'Pre-VAT Total (AED)',
      value: parseFormatPriceValueFromAPI(preVatAmount)
    },
    {
      title: 'VAT (5%)',
      value: parseFormatPriceValueFromAPI(vatAmount)
    },
    {
      isVisible: Boolean(nonTaxableCharge),
      title: 'Other Charges (non-taxable)',
      value: parseFormatPriceValueFromAPI(nonTaxableCharge)
    },
    {
      title: 'Grand Total (AED)',
      value: parseFormatPriceValueFromAPI(orderTotal)
    }
  ];
  return (
    <div className='flex flex-col gap-2 md:gap-4 self-baseline border border-platinum bg-lightgray p-4 md:p-6 w-full rounded-lg'>
      <Text
        {...{
          className: 'font-medium text-base',
          content: 'Price Details'
        }}
      />
      {priceSummaryList.map(
        (
          {
            isVisible = true,
            subTitle,
            subTitleClass = '',
            title,
            value,
            valueClass = ''
          },
          index
        ) =>
          isVisible && (
            <div
              key={index}
              className='flex justify-between text-sm font-light md:font-normal text-nero'
            >
              <div className='flex gap-1'>
                <Text {...{ content: title }} />
                {subTitle && (
                  <Text
                    {...{ content: `(${subTitle})`, className: subTitleClass }}
                  />
                )}
              </div>
              <Text {...{ content: value, className: valueClass }} />
            </div>
          )
      )}
      {isLoginAndPaymentCTAVisible && (
        <div className='hidden md:flex flex-col gap-4'>
          <CTA
            {...{
              buttonColor: 'bg-brand-gradient p-10 text-white',
              children: 'Proceed To Payment',
              className: `w-full py-3 ${
                disableProceedToPayment
                  ? 'pointer-events-none'
                  : 'pointer-events-auto'
              }`,
              disabled: disableProceedToPayment,
              onClick: onClickProceedToPayment
            }}
          />
          <CTA
            {...{
              buttonColor: 'bg-white border border-brand p-10 text-brand',
              children: 'Login To Collaborate',
              className: `w-full py-3 ${
                disableLoginButton
                  ? 'pointer-events-none'
                  : 'pointer-events-auto'
              }`,
              disabled: disableLoginButton,
              onClick: onClickLogin
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PriceSummary;
