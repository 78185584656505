import { useState } from 'react';

import { CartInfo, HostCartEventInfo } from '@/components/atomic/molecules';

const CartEventInfoOrganism = ({
  header,
  isDefaultCart,
  userEventSummary = {},
  isCartEditable = true
}) => {
  const [showEventDetail, setShowEventDetail] = useState(!isCartEditable);
  const toggleShowEventDetail = () => setShowEventDetail(!showEventDetail);
  const { id: userEventId, userEventDetails: [userEventDetails] = [] } =
    userEventSummary;
  const isEventDetailsAvailable = showEventDetail && userEventDetails;

  return (
    <div className='mb-5'>
      <CartInfo
        {...{
          ...header,
          eventId: userEventId,
          isCartEditable,
          isDefaultCart,
          showEventDetail,
          toggleShowEventDetail
        }}
      />
      {isEventDetailsAvailable && (
        <HostCartEventInfo {...{ ...userEventDetails }} />
      )}
    </div>
  );
};

export default CartEventInfoOrganism;
