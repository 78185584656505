import { useRouter } from 'next/router';

import { CollectionSubHeader, RibbonButton } from '@/components/atomic/atoms';

const BottomHeader = () => (
  <div className='flex flex-row mt-4 Montserrat'>
    <div className='self-center leading-5 text-lg'>
      <div className='font-extrabold'>
        Find everything you need to make your events a success!
      </div>
      <div>
        Whether it’s event equipment rental or corporate event supplies,
      </div>
      <div>enjoy end-to-end solutions from ideas all the way to execution.</div>
    </div>
  </div>
);

const WhyChooseHeader = ({
  title,
  subTitle,
  buttonText,
  buttonUrl,
  isOpenInSamePage
}) => {
  const Router = useRouter();
  return (
    <div className='flex flex-row justify-around'>
      <div>
        <CollectionSubHeader
          {...{
            title,
            subTitle,
            underline: true,
            titleClass: 'flex flex-row uppercase tracking-widest'
          }}
        />
        <BottomHeader />
      </div>
      <div className='self-start mt-3'>
        <RibbonButton
          className='rounded-full uppercase px-8 py-2 tracking-wider'
          onClick={() =>
            isOpenInSamePage
              ? Router.push(buttonUrl)
              : window.open(buttonUrl, '_blank')
          }
        >
          {buttonText}
          <span style={{ fontSize: 19 }}> →</span>
        </RibbonButton>
      </div>
    </div>
  );
};

export default WhyChooseHeader;
