import Image from 'next/image';

import { Text } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const PromoCodeIcon = ({ alt, className, icon, removePromoCode }) => (
  <div className={className}>
    <Image
      src={`${staticMediaStoreBaseURL}/icons/${icon}`}
      width={20}
      height={20}
      alt={alt}
      className='cursor-pointer'
      onClick={removePromoCode}
    />
  </div>
);

const ApplyPromoCodeSuccess = ({ promoCode, promoCodeAmount }) => (
  <div>
    <Text
      {...{
        content: `Yay! You saved AED ${promoCodeAmount}`,
        className: 'text-base font-medium'
      }}
    />
    <Text
      {...{
        content: `${promoCode} applied`,
        className: 'text-xs font-medium text-dim-gray mt-1'
      }}
    />
  </div>
);

const EditPromoCode = ({ promoCode, promoCodeAmount, removePromoCode }) => (
  <div className='flex border border-platinum rounded-lg w-full'>
    <PromoCodeIcon
      {...{
        className: 'w-14 bg-platinum self-center justify-center flex h-16',
        icon: 'success-with-border.svg'
      }}
    />
    <div className='flex justify-between px-3 py-2 flex-1'>
      <ApplyPromoCodeSuccess {...{ promoCode, promoCodeAmount }} />
      <PromoCodeIcon
        {...{
          removePromoCode,
          className: 'self-center',
          alt: 'remove promocode',
          icon: 'close-with-border-gray.svg'
        }}
      />
    </div>
  </div>
);

export default EditPromoCode;
