import { AlertMessage, CTA } from '@/components/atomic/atoms';
import { UNAVAILABLE_CART_ITEM_ALERT } from '@/config/common';

const UnavailableItemListOrganism = ({
  CartItemRow,
  cartItemTitleClass,
  deleteItem,
  itemList = [],
  priceLabelClass
}) => (
  <div className='border border-red-500 px-3 py-4 md:p-6 rounded-2.5 flex flex-col gap-3'>
    <AlertMessage
      {...{
        message: UNAVAILABLE_CART_ITEM_ALERT
      }}
    />
    {itemList.map((items, index) => (
      <CartItemRow
        key={index}
        {...{
          ...items,
          cartItemTitleClass,
          deleteItem,
          priceLabelClass
        }}
      />
    ))}
    <div className='flex justify-end'>
      <CTA
        {...{
          buttonColor:
            'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white',
          children: 'Remove All',
          className: 'text-sm font-medium w-40 py-2',
          onClick: () =>
            itemList.map(({ id: cartItemId }) => deleteItem({ cartItemId }))
        }}
      />
    </div>
  </div>
);

export default UnavailableItemListOrganism;
