import config from 'config';
import Link from 'next/link';
import { useState } from 'react';

import { Button, ProductPrice } from '@/components/atomic/atoms';
import { useUIContext } from '@/components/context/uiContext/ManagedUiContext';
import { PRODUCT_TYPE } from '@/config/common';
import { PAGE_NAME, captureGTMEventAddToCart } from '@/lib/gtm';
import { getCartDetailById } from '@/services/hostCart.service';
import { isPlannerLoggedIn } from '@/services/identity.service';

const typeValue = {
  decrease: -1,
  increase: 1
};

const ItemQuantitySpinner = ({
  changeQuantity,
  handleOnBlur,
  handleValueChange,
  minBookable,
  productData,
  quantity
}) => (
  <div className='w-1/2 xl-1:w-auto flex flex-row h-10 max-w-30 min-w-15 xl-1:min-w-30 rounded-lg border-cinnabar border'>
    <button
      disabled={quantity === minBookable}
      onClick={(e) => changeQuantity(e, 'decrease')}
      data-action='decrement'
      className='text-cinnabar h-full w-8 min-w-6 xl:min-w-8 overflow-hidden box-border rounded-l cursor-pointer border-r border-cinnabar'
    >
      <span className='m-auto text-3xl font-normal'> − </span>
    </button>
    <input
      type='number'
      className='appearance-none outline-none focus:outline-none text-center w-full font-medium hover:text-nero focus:text-nero
      text-base flex items-center'
      name={quantity}
      value={quantity}
      max={productData.maxBookable || 10}
      onChange={(e) => handleValueChange(e.target.value)}
      onBlur={handleOnBlur}
      readOnly
    />
    <button
      disabled={quantity === productData.maxBookable}
      onClick={(e) => changeQuantity(e, 'increase')}
      data-action='increment'
      className='text-cinnabar h-full w-8 min-w-6 xl:min-w-8 overflow-hidden box-border rounded-r cursor-pointer border-l border-cinnabar'
    >
      <span className='m-auto text-3xl font-normal'> + </span>
    </button>
  </div>
);

const ProductPriceLink = ({ product }) => (
  <Link
    href={`/products/${product.slug}`}
    passHref
  >
    <a
      className='cursor-pointer h-full pt-2.5 block'
      target='_blank'
    >
      <ProductPrice {...{ product }} />
    </a>
  </Link>
);

const CartItemControl = ({
  product,
  quantity,
  searchPage,
  setProductQuantityCount,
  setQuantity
}) => {
  const [isQuantityInvalid, setIsQuantityInvalid] = useState(false);
  const { createCart, updateCartInPortalHeader } = useUIContext();

  const {
    assignedToCategories,
    availableDiscount,
    id,
    name,
    price: { price },
    type: productType
  } = product;

  const minBookable = product?.minBookable || 1;
  const maxBookable = product?.maxBookable || 10;

  const disableAddToCartButton = isPlannerLoggedIn
    ? config.isOpsToPlannerPortalBlocked
    : false;

  const revertQuantityToMin = () => {
    setQuantity(minBookable);
  };

  const handleAddToCart = async (e) => {
    e.stopPropagation();
    captureGTMEventAddToCart({
      assignedToCategories,
      availableDiscount,
      id,
      name,
      pageName: PAGE_NAME.CLP_AND_PLP.label,
      price,
      quantity,
      type: productType
    });
    if (!isQuantityInvalid) {
      const prevQuantity = quantity;
      const isCartCreated = await createCart({
        product,
        quantity,
        revertQuantityToMin
      });

      if (isCartCreated) {
        const { entity } = await getCartDetailById({
          cartId: isCartCreated.cartId
        });

        updateCartInPortalHeader && updateCartInPortalHeader(entity);

        setProductQuantityCount(prevQuantity);
        setTimeout(() => {
          setProductQuantityCount(null);
        }, 5000);
      }
    }
  };

  const changeQuantity = (e, quantityType) => {
    e.stopPropagation();
    setQuantity((prevQuantity) => prevQuantity + typeValue[quantityType] ?? 0);
  };

  const handleValueChange = (value) => {
    const inputValue = parseFloat(value);
    if (inputValue < 0) {
      return;
    }
    setQuantity(inputValue);
    setIsQuantityInvalid(
      inputValue > maxBookable ||
        inputValue < minBookable ||
        Number.isNaN(inputValue)
    );
  };

  const handleOnBlur = (e) => {
    e.stopPropagation();

    if (e.target.value < minBookable || e.target.value === '') {
      setQuantity(minBookable);
      setTimeout(() => {
        setIsQuantityInvalid(false);
      }, 1000);
    }

    if (e.target.value > maxBookable) {
      setQuantity(maxBookable);
      setTimeout(() => {
        setIsQuantityInvalid(false);
      }, 1000);
    }
    return null;
  };

  return (
    <div className='fade hidden lg:group-hover:block absolute bottom-0 left-0 right-0 transition-all ease-in-out duration-700'>
      <div className='p-4 self-end w-full cursor-pointer'>
        <div className='flex items-center justify-center flex-row space-x-2 xl-1:space-x-4'>
          {productType === PRODUCT_TYPE.CART && (
            <ItemQuantitySpinner
              {...{
                changeQuantity,
                handleOnBlur,
                handleValueChange,
                minBookable,
                productData: product,
                quantity
              }}
            />
          )}
          <div
            className={`${
              productType === PRODUCT_TYPE.CART ? 'max-w-38' : 'max-w-44'
            } xl:min-w-30 xl-1:min-w-38 w-1/2 xl-1:w-full`}
          >
            <Button
              className='text-sm xl-1:text-base text-white font-Montserrat text-center font-medium leading-5 bg-brand-gradient
              px-1.5 xl-1:px-3 py-2 rounded-lg block h-10'
              disabled={disableAddToCartButton}
              onClick={(e) => handleAddToCart(e)}
            >
              Add to cart
            </Button>
          </div>
        </div>
        {searchPage && <ProductPriceLink {...{ product }} />}
      </div>
    </div>
  );
};

export default CartItemControl;
