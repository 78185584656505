import { useEffect, useRef } from 'react';

import { Divider, ProductPriceLabel } from '@/components/atomic/atoms';
import {
  ProductDescription,
  ProductDetail,
  ProductInfo,
  ProductNotes,
  ProductQuantityRangeAlertError
} from '@/components/atomic/molecules';
import { getSerializedRichText } from '@/helpers/editor';

const ProductSummaryDetailOrganism = ({ product, showQuantityRangeAlert }) => {
  const {
    availableDiscount,
    description,
    displayAttributes,
    maxBookable,
    minBookable,
    name,
    note,
    price,
    shortDescription,
    type: productType
  } = product;
  const showErrorRef = useRef();

  useEffect(() => {
    showQuantityRangeAlert && showErrorRef.current.scrollIntoViewIfNeeded();
  }, [showQuantityRangeAlert]);

  return (
    <div className='product-info w-full md:w-2/3 px-5 md:pr-20 md:pl-0'>
      <ProductInfo {...{ name, shortDescription }} />
      <div className='block md:hidden my-5'>
        <ProductPriceLabel {...{ availableDiscount, price, productType }} />
        {showQuantityRangeAlert && (
          <ProductQuantityRangeAlertError
            {...{
              className: 'block md:hidden',
              maxBookable,
              minBookable,
              showErrorRef
            }}
          />
        )}
      </div>
      <Divider />
      {getSerializedRichText(description) && (
        <ProductDescription {...{ description }} />
      )}
      {displayAttributes.length > 0 && (
        <ProductDetail {...{ displayAttributes }} />
      )}
      {getSerializedRichText(note) && <ProductNotes {...{ note }} />}
    </div>
  );
};

export default ProductSummaryDetailOrganism;
